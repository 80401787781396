/**
 * 判断手机端还是pc端
 * @returns {RegExpMatchArray}
 * @constructor
 */
const UtilsIsMobile = () => {
    return navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
    )
}


const UtilGetDoMain = () => {
    return document.domain;
}

const UtilNavTo = (url) => {
    window.location.href = url;
}


export {
    UtilsIsMobile,
    UtilGetDoMain,
    UtilNavTo,
}