import axios from "axios";
import {ElMessage} from "element-plus";
import {ServiceHost} from "@/config";
import {UtilGetDoMain} from "@/utils/utils_tools";

// systemId = UtilGetSystemInfoId();

const UtilsAxiosGet = (options) => {
    let headers = {
        MyDomain: UtilGetDoMain()
    };
    return new Promise((res, err) => {
        axios({
            method: "get",
            url: `${ServiceHost}${options.url}`,
            headers: headers
        }).then((re) => {
            let resultData = re.data;
            res(resultData);
        }).catch((e) => {
            ElMessage.error(JSON.stringify(e));
            err(e);
        });
    });
}

const UtilsAxiosPost = (options) => {
    let headers = {
        MyDomain: UtilGetDoMain()
    };
    return new Promise((res, err) => {
        axios({
            method: "post",
            url: `${ServiceHost}${options.url}`,
            data: options.data,
            headers: headers
        }).then((re) => {
            let resultData = re.data;
            res(resultData);
        }).catch((e) => {
            ElMessage.error(JSON.stringify(e));
            err(e);
        });
    });
}

export {
    UtilsAxiosGet,
    UtilsAxiosPost
}