<template>
  <div id="app">
    <template v-if="systemError">
      {{ errMsg }}
    </template>
    <template v-else>
      <router-view/>
    </template>
  </div>
</template>

<script setup>
  import {onMounted, ref} from "vue";

  import {Dialog} from 'vant';
  import 'vant/es/dialog/style/index';

  import {visitSubmit} from "@/api/visit";

  let errMsg = ref("");
  let systemError = ref(false);


  const appInit = () => {
    visitSubmit().then((res) => {
      if (res.code !== 0) {
        systemError.value = true;
        errMsg.value = res.msg
        Dialog.alert({
          title: "system error",
          confirmButtonText: "close",
          message: res.msg
        }).then(() => {
          window.location.href = "/";
        });
      }
    });
  };

  onMounted(() => {
    appInit();
  });
</script>

<style>
*{
  margin: 0;
  padding: 0;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
</style>
